import TextField from "../../../../components/textfield";
import {
  required,
  mustBePhone,
  composeValidators,
} from "../../../../components/form";
import { useForm } from "utils/useForm";
import { UPDATE_INSURED_PERSON_MUTATION } from "./graphql";
import {
  UpdateInsuredPersonMutation,
  UpdateInsuredPersonMutationVariables,
} from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";

type PropsParent = {
  insuredPersonIds: string[];
  open: boolean;
  onClose: () => void;
  description?: string;
};

type Props = PropsParent;

export const PhoneUpdateDialog = (props: Props) => {
  const { registerField, errors, handleSubmit } = useForm<{ phone: string }>();

  const [updatePhone, { loading }] = useMutationForApp<
    UpdateInsuredPersonMutation,
    UpdateInsuredPersonMutationVariables
  >(UPDATE_INSURED_PERSON_MUTATION, {
    onCompleted: () => window.location.reload(),
  });

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Update telefoonnummer</DialogTitle>
      <DialogContent>
        {props.description && (
          <DialogContentText>{props.description}</DialogContentText>
        )}
        <TextField
          data-test="update_phone_textfield"
          // @ts-ignore
          floatingLabelText={"Nieuw telefoonnummer"}
          hintText="Voer het nieuwe telefoonnummer in"
          name="phone"
          errorText={errors.phone}
          fullWidth
          {...registerField("phone", {
            validation: composeValidators(required, mustBePhone),
          })}
        />
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={props.onClose} />
        <DialogConfirmButton
          onClick={() =>
            handleSubmit((data) =>
              updatePhone({
                variables: {
                  input: {
                    insuredPersonIds: props.insuredPersonIds,
                    phone: data.phone,
                  },
                },
              })
            )
          }
          disabled={loading}
        >
          Bevestig
        </DialogConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
