import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { composeValidators } from "components/form";
import { useState } from "react";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { mustBePhone, required } from "utils/validator";
import { useUpdateAccountMutation } from "../graphql";
import { EditButton } from "../../../components/button/buttons";

type Props = {
  accountId: string;
  currentPhone: string | null;
};

type Form = {
  phone: string;
};

export const UpdateAccountPhoneButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  const { values, registerField, handleSubmit, errors } = useForm<Form>({
    initialValues: { phone: props.currentPhone || "" },
  });

  const errorsPerField = textFieldErrorProps(errors);

  const [updateAccount, { loading }] = useUpdateAccountMutation(
    props.accountId,
    { input: { phone: values.phone } }
  );

  const close = () => {
    if (!loading) setOpen(false);
  };
  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle>Pas telefoonnummer aan</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            {...registerField("phone", {
              validation: composeValidators(required, mustBePhone),
            })}
            {...errorsPerField("phone")}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            Annuleer
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleSubmit(() => {
                updateAccount();
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
